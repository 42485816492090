import { CREATE_NOTE, UPDATE_NOTE, DELETE_NOTE } from "./noteConstants";
import { toastr } from "react-redux-toastr";
import cuid  from 'cuid';
import _ from 'lodash';


export const updateNote = (note, collectionId) => {
    return {
        type: UPDATE_NOTE,
        payload: {
            id: collectionId,
            note
        }
    }
}

export const deleteNote = (note, collectionId) => {
    return {
        type: DELETE_NOTE,
        payload: {
            id: collectionId,
            note
        }
    }
}
// this create function is actually an upsert
export const createNote = ({videoId, noteText, reminderText, time, noteId}) => async (dispatch, getState, {getFirebase, getFirestore}) => {
    try {
        const firestore = getFirestore();
        const uid = _.get(getState(), 'firebase.profile.uid');
        await firestore.get(`notes/${uid}`);       
        const state = getState();
        const videoNotes= _.get(state, `firestore.data.notes[${uid}][${videoId}]`, []).filter(note => note.id !== noteId);
        const newNote = {
            id: noteId || cuid(),
            videoId,
            noteText: noteText || '',
            reminderText: reminderText || '',
            time
        };
        let payload;
        if(!newNote.noteText && !newNote.reminderText) {
            // delete note
            payload = {[videoId] : [...videoNotes]};
        } else {
            payload = {[videoId] : _.sortBy([...videoNotes, newNote ], 'time')};

        }
        await firestore.update(`notes/${uid}`, payload);
        await firestore.get(`notes/${uid}`);
        dispatch({ type: CREATE_NOTE, payload });
        
    } catch (error) {
        toastr.error('Could not update your notes! Check connection and login status.')
        console.log(error);
    }
}
const getTimeString = (seconds) => {
    return new Date(seconds * 1000).toISOString().substr(11, 8);
}
const formatNotes = (notes, video) => {
   return  _.sortBy(notes.map(note => {
        return {
            note: note.noteText,
            reminders : note.reminderText,
            video : video.name,
            time: getTimeString(note.time)
        }
    }, 'time'));
}
export const exportNotes = () => async (dispatch, getState, {getFirebase, getFirestore}) => {
    // replace with a pdf generator
    
    try{
        const state = getState();
        const uid = _.get(state, 'firebase.profile.uid');       
        const notesMap = _.get(state,`firestore.data.notes[${uid}]`, {});
        const allNotes = _.flatten(Object.values(notesMap)).filter(note => typeof note === 'object');
        const collections = _.get(state, 'collections', {});
        const collectionIds = collections.allIds;
        const notesByCollection = collectionIds.map(collectionId => {
            const collection = collections.byId[collectionId];
            const notes = {
                [collection.name]: collection.videos.allIds.map(videoId => {
                    const collectionVideo = collection.videos.byId[videoId];
                    return {
                        [collectionVideo.name] : formatNotes(allNotes.filter(note => note.videoId === videoId), collectionVideo)
                    }

                })
            }
            return notes;
        });
        const combinedNotes = notesByCollection.reduce((prev, noteGroup) => {
            const collectionName = Object.keys(noteGroup)[0];
            prev[collectionName] = noteGroup[collectionName];
            return prev;
        }, {});
        console.log(JSON.stringify(combinedNotes))
        toastr.success('NOT_WORKING_YET, Notes sent!! Please check the email associated with this account.');
    } catch(error){
        toastr.error('Could not export your notes! Check connection and login status. If the problem persists please reach out to me directly at ricky@grapplerpad.com')
        console.log(error);
    }
}