import React, { Component } from 'react'
import { toastr } from "react-redux-toastr";
import { Button } from 'semantic-ui-react';

class TestComponent extends Component {
    render() {
        
        return (
            <div>
                <Button onClick={() => toastr.success('Testing area', 'please ignore if you arent on the grappler pad dev team')}> Here</Button>
            </div>
        )
    }
}
export default TestComponent;