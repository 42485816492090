import NavBar from "../features/navigation/NavBar/NavBar";
import React, { Component, Fragment } from "react";
import { Container } from 'semantic-ui-react';
import Dashboard from "../features/study/Dashboard/Dashboard";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import HomePage from "../features/home/HomePage"
import SettingsDashboard from "../features/user/Settings/SettingsDashboard";
import CollectionList from "../features/library/Collection/CollectionList";
import ModalManager from "../features/modals/ModalManager";
import TestComponent from "../features/Test/TestComponent/TestComponent";
import SetupForm from "../features/library/SetupForm/SetupForm";
import NoteCollectionList from "../features/diary/NoteCollection/NoteCollectionList";
import Notebook from "../features/diary/Notebook/Notebook";

class App extends Component {
  render() {
    return (
      <Fragment>
        <ModalManager />
        <NavBar />
        <Route exact path='/' component={HomePage} >
          <Redirect to='/home' />
        </Route>
        <Route path='/(.+)' render={() => (
          <Fragment>
            <Container>
            <Switch key={this.props.location.key}>
              <Route exact path='/library' component={CollectionList} />
              <Route path='/library/:collectionId' component={SetupForm} />
              <Route exact path='/study/:collectionId' component={Dashboard} />
              <Route exact path='/notepad' component={NoteCollectionList} />
              <Route path='/notepad/:collectionId' component={Notebook} />
              <Route path='/settings' component={SettingsDashboard} />
              <Route path='/test' component={TestComponent} />
              <Route path='/home' component={HomePage} />
            </Switch>
            </Container>
          </Fragment>
        )} />
    </Fragment>)
  }
}


export default withRouter(App);
