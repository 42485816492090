import { CREATE_COLLECTION, UPDATE_COLLECTION, DELETE_COLLECTION, FETCH_COLLECTIONS } from "./collectionConstants";
import { asyncActionStart, asyncActionError, asyncActionFinish} from "../../async/asyncActions";
import fetch from 'node-fetch';
import { toastr } from "react-redux-toastr";
import { updateLibrarySettings } from "./librarySettingsActions";
import { updateLabelSettings } from "../../study/Dashboard/labelSettingsActions";

export const createCollection = (collection) => {
    return {
        type: CREATE_COLLECTION,
        payload: {
            collection
        }
    }
}

export const updateCollection = (collection) => {
    return {
        type: UPDATE_COLLECTION,
        payload: {
            collection
        }
    }
}

export const deleteCollection = (collectionId) => {
    return {
        type: DELETE_COLLECTION,
        payload: {
            collectionId
        }
    }
}

export const initDataStore = () => async (dispatch, getState, {getFirebase, getFirestore}) => {
    try {
        const firebase = getFirebase();
        const firestore = getFirestore();
        let data = firebase.storage().ref();
        const fileRef = data.child('data/collections_0_0_3.json')
        dispatch(asyncActionStart())
        const location = await fileRef.getDownloadURL();
        const res = await fetch(location);
        const json = await res.json();
        dispatch(asyncActionFinish());
        const auth = await firebase.auth();
        const uid = auth.currentUser.uid;
        await firestore.get(`users/${uid}`);
        dispatch({type: FETCH_COLLECTIONS, payload: { collections: json }});
        await firestore.get(`notes/${uid}`);
        dispatch(updateLibrarySettings())
        dispatch(updateLabelSettings())
        
    } catch (error) {
        dispatch(asyncActionError())
        toastr.error('Could not fetch necessary files! Check connection and login status.')
        console.log(error);
    }
}