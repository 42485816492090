import { createReducer } from "../../../common/util/reducerUtils";
import { CREATE_NOTE, LOAD_NOTES } from "./noteConstants";


const createNote = (state, payload) => {
    return {
        ...state,
        ...payload
    };
}

const loadNotes = (state, payload) => {
    return payload.notes
}

export default createReducer({}, {
    [CREATE_NOTE]: createNote,
    [LOAD_NOTES]: loadNotes
})