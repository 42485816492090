import React, { Component } from 'react';
import { Icon, List, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getBookmark } from '../../study/VideoPlayer/VideoPlayer';
import { withFirebase } from 'react-redux-firebase';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { setVideoSettings } from '../../study/VideoPlayer/videoSettingsActions';
import { openModal } from '../../modals/modalActions';

const mapState = ({ collections, async, firebase, firestore }, { match }) => {
    const id = match.params.collectionId;
    const videos = collections.byId && collections.byId[id].videos;
    const videoIds = _.get(videos, 'allIds');
    const uid = firebase.auth.uid;
    const notes = _.get(firestore,`data.notes[${uid}]`, null);
    if (notes && videos && videos.allIds) {
        return {
            collectionId: id,
            videos: _.sortBy(Object.values(videos.byId), 'name'),
            notes: videoIds && videoIds.reduce((prev, currId) => {
                prev[currId] = notes[currId]
                return prev;
            }, {}),
            loading: async.loading,
        };
    }
    return {
        collectionId: id,
        videos: [],
        loading: async.loading,
        notes: {}

    }
}
const actions = {
    setVideoSettings,
    openModal
}

const Notes = ({ notes, video, collectionId, history, setVideoSettings, openModal }) => {
    return (
        <List.Item as='li'>
            {video.name}
            <List.List as='ul'>
                {notes && notes.map(note => {
                    return (
                        <List.Item key={note.id} as='li'>
                        <Icon 
                            name='edit' 
                            onClick={() => {
                                openModal('NoteModal', {
                                    note: note, 
                                    time: note.time,
                                    videoId: video.id,
                                    collectionId,
                                    currentBookmark: video.name,
                                    player: {play: () => {}} 
                                });  
                            }}
                        />
                        {`${note.noteText}  `}
                        <Icon name='video play' onClick={(evt) => {
                            evt.preventDefault();
                            setVideoSettings({ collectionId, videoId: video.id, bookmarkId: getBookmark(parseFloat(note.time), Object.values(video.bookmarks.byId)), time: parseFloat(note.time)});
                            history.push(`/study/${collectionId}`);
                        }} 
                        />
                        </List.Item>
                    )
                })}
            </List.List>

        </List.Item>
    )
}

class Notebook extends Component {
    render() {
        const { videos, notes, collectionId, history, setVideoSettings, openModal } = this.props;
        return (
            <Grid>
            <List as='ul'>
                {videos.map(video => <Notes key={video.id} video={video} notes={notes[video.id]} collectionId={collectionId} history={history} setVideoSettings={setVideoSettings} openModal={openModal} />)}
            </List>
            </Grid>
        )
    }
}

export default withRouter(withFirebase(connect(mapState, actions)(Notebook)));