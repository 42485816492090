import React from 'react'
import { Form, Label } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';

/* example usage
    <Field 
        name='date' 
        component={DateInput}
        dateFormat='dd LLL yyyy h:mm a'
        showTimeSelect
        timeFormat='HH:mm'
        placeholder='Event date' 
    />

    make sure to string the date when passing to a component else you might get object not valid child
*/



const DateInput = ({
    input,
    width,
    placeholder,
    meta: { touched, error },
    ...rest
}) => {
    return (
        <Form.Field error={touched && !!error}>
            <DatePicker
                {...rest}
                placeholderText={placeholder}
                selected={input.value ? new Date(input.value): null}
                onChange={input.onChange}
            />
            {touched && error && (
                <Label basic color="red">
                    {error}
                </Label>
            )}
        </Form.Field>
    )
}

export default DateInput;
