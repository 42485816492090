import React, { Fragment } from 'react'
import { Segment, Button } from 'semantic-ui-react';
import { exportNotes } from '../diary/Note/noteActions';
import { connect } from 'react-redux';

const actions = {
  exportNotes
}

const HomePage = ({history, exportNotes}) => {
    return (
      <Fragment>
        <h1> Welcome to GrapplerPad!</h1>
        <p> 
        Welcome to GrapplerPad
It started with a question:"If John Danaher was a programmer, thenwhat would he build?" If the incredible instructional content put out by John is the heel hook, then this app is your own private ashi garami. Completely lost? An eager white belt, perhaps? Do not worry, take a deep breath;now click here. In the seemingly distant year of 2002, I had the distinct honor to be able to take private lessons with John Danaher in the now world-renowned blue basement. Twice a week for over a year, John showed me elements of grappling that helped me, a 19-year-old, fat, asthmatic, math nerd to experience what it was  like to submit an olympian, a golden gloves contender, and many other garden variety uber-athletes. Grappling is a beautiful thing. Years later, and thousands of miles away, I discovered that I could continue my tutelage under John. All it required was the purchase of his first video for the Enter the System series.  I was quickly hooked.Isimply could not  resist picking up everything he put out over the course of a year. . One day, as I winced empathetically watching John demonstrate proper strangling mechanics;  I realized that it is impossible to learn all these details in one viewing, in two viewings or perhaps even a series of viewings over the course of a jiujitsu journey. I would have to review this material for years and differently, from different paradigms, as I grew and matured as a martial artist. Then I thought back: John, himself, had his infamous notebook that he, WITHOUT FAIL, made notes in every day. I asked him if I could take notes during our sessions and responded that I “absolutely" should. After that whenever I needed to write something down John would let me pause our lessonto do so. Some days this proved to be a wise secondary escape route from John's submissions;the primary method is of course, tapping like a typewriter. If John made notes, then so would I. The question then became how? 

Many years after my first contact with John Danaher, I have developed what I think is the perfect companion to Professor Danaher’s systematic approach to the art of grappling: a digital pad on which to collect, annotate, connect, revisit and mark his video instructionals from BJJ Fanatics. Of course, this app can be applied to any Fanatics content, but it is tailored in a bespoke manner to capture the richness and depth of details that only John Danaher could offer a student.
        
        </p>
        <Segment>
          <a href='https://youtu.be/_NOrWp3GDrA'> Setup instructions </a>
        </Segment>
        <Segment>
          <p>If for any reason you no longer want to use the GrapplerPad app anymore. You can export your notes by hitting the button below.</p>
            <Button onClick={() => exportNotes()}> Export Notes </Button>
        </Segment>
      </Fragment>
    )
}

export default connect(null, actions)(HomePage);