import React, { Component } from 'react'
import { connect } from 'react-redux';
import { updateLinks } from '../Collection/librarySettingsActions';
import { Grid, Segment, Header, Button, Form } from 'semantic-ui-react';
import { reduxForm, Field } from 'redux-form';
import TextInput from '../../../common/form/TextInput';
import _ from 'lodash';

const getNewLinks = (links, values) => {
    return Object.keys(values).reduce((prev, currValue) => {
        const videoId = links.filter(link => link.name === currValue)[0].id;
        prev.push({
            videoId,
            link: values[currValue]
        });
        return prev;
    },[])
}

const fetchCurrentLinks = (videos, links = []) => {
    const videoIds = videos.allIds;
    return videoIds.map(id => {
        const linkArr = links.filter(link => link.videoId === id);
        const link = linkArr.length > 0 ? linkArr[0].link : null;
        return ({
            link,
            name: videos.byId[id].name,
            id,

        });
    });
};
const computeValues = (links) => {
    return links.reduce((prev, currLink) => {
        prev[currLink.name] = currLink.link;
        return prev;
    }, {});
}
const actions = {
    updateLinks
}
const mapStateToProps = ({ collections, firebase, firestore }, { match }) => {
    const id = match.params.collectionId;
    const links = firebase.profile.links;
    const videos = collections.byId && collections.byId[id].videos;
    const labelledLinks = videos && fetchCurrentLinks(videos, links)
    if (labelledLinks) {
        return {
            linkData: {
                id,
                name: collections.byId[id].name,
                links: _.sortBy(labelledLinks, 'name')
            },
            initialValues: { ...computeValues(labelledLinks) }
        }
    }
    return {
        linkData: { id, links: []}
    }
}
class SetupForm extends Component {
  onFormSubmit = (values) => {
    const { links } = this.props.linkData;
    const id = this.props.linkData.id;
    const newLinks = getNewLinks(links, values);
    this.props.updateLinks(newLinks);
    this.props.history.push(`/study/${id}`)

  }
  render() {
    const { invalid, submitting, pristine, linkData: { links, name }, history, handleSubmit } = this.props;     
    return (
        <Grid>
            <Grid.Column width={10}>
                <Segment>
                    <Header sub content={name} />
                    <Form onSubmit={handleSubmit(this.onFormSubmit)} autoComplete='off'>
                    {links.map(link => 
                        (<Field 
                            key={link.name} 
                            name={link.name} 
                            type='text' 
                            component={TextInput} 
                            placeholder={`${link.name} paste download/video links here!`} 
                        />)
                    )}
                    <Button disabled={invalid || submitting || pristine} positive type="submit">
                            Submit
                    </Button>
                        <Button type="button" onClick={() => history.push(`/library`)}>Cancel</Button>
                    </Form>
                </Segment>
            </Grid.Column>
        </Grid>
    )
  }  
}

export default connect(mapStateToProps, actions)(reduxForm({ form: 'setupForm' })(SetupForm));