import React from 'react'
import { connect } from 'react-redux';
import LoginModal from './LoginModal';
import RegisterModal from './RegisterModal';
import TestModal from './TestModal';
import NoteModal from './NoteModal';

const modalLookup = {
    TestModal,
    LoginModal,
    RegisterModal,
    NoteModal
}

const mapStateToProps = (state) => ({
    currentModal: state.modals
})
const ModalManager = ({currentModal}) => {
    let renderedModal;
    if (currentModal) {
        console.log(currentModal)
        const {modalType, modalProps} = currentModal;
        const ModalComponent = modalLookup[modalType];
        renderedModal = <ModalComponent {...modalProps} />
    }
    return (
        <span>{renderedModal}</span>
    )
}

export default connect(mapStateToProps)(ModalManager);