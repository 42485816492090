import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton,
    BigPlayButton
} from 'video-react';

import _ from 'lodash';
import { Segment, Label } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import BookmarkTracker from './BookmarkTracker';

const mapStateToProps = (state, { match }) => {
    const collectionId = match.params.collectionId;
    return {
        videoSettings: state.videoSettings[collectionId],
        collection: _.get(state.collections, `byId[${collectionId}]`)
    };
}

export const getBookmark = (time = 0, bookmarks = []) => {
    const sortedMarks = _.sortBy([...Object.values(bookmarks), { time }], 'time');
    let idx;
    sortedMarks.forEach((mark, i) => {
        if (mark.time === time) {
            idx = i - 1;
        }
    });
    return _.get(sortedMarks, `[${idx}]`, { name: '' }).name;
}
const getVideoSettings = (videoSettings, collection) => {

    const defaultSettings = {
        videoId: _.get(collection, 'videos.allIds[0]', ''),
        collectionId: _.get(collection, 'id', ''),
        videoName: 'Volume 1',
        collectionName: _.get(collection,'name',''),
        videoUrl: '',
        time: 0,
        bookmarks: []
    }
    if (!videoSettings) {
        return defaultSettings;
    }
    return videoSettings;
}

class VideoPlayer extends Component {
    constructor() {
        super()
        this.player = React.createRef();
        this.subscription = () => {};
    }
    seekOnReady() {
        // runs the seek once after the player is ready
        const player = this.player.current;
        const time = _.get(this.props, 'videoSettings.time',0);
        let unSubscribe = this.subscription;
        unSubscribe = player.subscribeToStateChange(() => {
            if((player.getState().player.readyState > 1)) {
                player.seek(time + 1);
                player.play();
                unSubscribe()
            }
        })

    }
    componentDidMount() {
        const time = _.get(this.props, 'videoSettings.time', null);
        if(time) {
            this.seekOnReady();
        }
    }
    componentDidUpdate(prevProps) {
        const player = this.player.current;
        const time = _.get(this.props, 'videoSettings.time');
        const videoUrl = _.get(this.props, 'videoSettings.videoUrl', '');
        const prevVideoUrl = _.get(prevProps, 'videoSettings.videoUrl', '');
        
        if(videoUrl === prevVideoUrl) {
            player.seek(time + 1);
            player.play();
        } else {
            player.load();
            this.seekOnReady();
        }
    }
    render() {
        const { videoSettings, collection } = this.props
        const { videoUrl, videoId, collectionId, videoName, collectionName, bookmarks, time } = getVideoSettings(videoSettings, collection);
        return (
            <Fragment>
                <Segment attached='top'><Label attached='bottom left'>{collectionName}</Label><Label attached='bottom right' style={{ top: '0' }}><em>{videoName}</em></Label></Segment>
                <Player autoPlay startTime={time + 1} ref={this.player} playsInline>
                    <BigPlayButton />
                    <source src={videoUrl}/>
                    <ControlBar >
                        <ReplayControl seconds={10} order={1.1} />
                        <ForwardControl seconds={30} order={1.2} />
                        <VolumeMenuButton order={4} />
                        <CurrentTimeDisplay order={4.1} />
                        <TimeDivider order={4.2} />
                        <PlaybackRateMenuButton rates={[2, 1.5, 1.25, 1.1, 1, 0.9, 0.5]} order={7.1} />
                    </ControlBar>
                </Player>
                <Segment attached='bottom' textAlign='center'>
                <BookmarkTracker videoId={videoId} collectionId={collectionId} bookmarks={bookmarks} player={this.player}/>
                </Segment>
            </Fragment>
        );
    }
}

export default withRouter(connect(mapStateToProps, null)(VideoPlayer));