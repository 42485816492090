import { UPDATE_COLLECTION_SETTINGS, UPDATE_LIBRARY_SETTINGS } from './librarySettingsConstants';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';

export const LIBRARY_SETTINGS_ENUM = {
    COMPLETE: 'complete',
    PARTIAL: 'partial',
    NOT_OWNED: 'not owned'
};

export const updateCollectionSettings = (id, setting) => {
    return {
        type: UPDATE_COLLECTION_SETTINGS,
        payload: { id, setting }
    }
};
const getSetting = (numVideos, numLinks) => {
    if(numLinks === 0) {
        return LIBRARY_SETTINGS_ENUM.NOT_OWNED;
    }
    if(numLinks < numVideos) {
        return LIBRARY_SETTINGS_ENUM.PARTIAL;
    }
    if(numLinks === numVideos) {
        return LIBRARY_SETTINGS_ENUM.COMPLETE;
    }
    if(numLinks > numVideos) {
        throw new Error('validation error: more links than videos');
    }
}
export const updateLibrarySettings = () => (dispatch, getState) => {
    const currentState = getState();
    const collections = currentState.collections || {};
    const links = currentState.firebase.profile.links || [];
    const librarySettings = {}
    const collectionIds = collections.allIds;
    collectionIds && collectionIds.forEach(id => {
        const videoIds = collections.byId[id].videos.allIds;
        const numVideos = videoIds.length;
        const existingLinks = links.map(link => link.videoId).filter(id => videoIds.includes(id));
        const numLinks = existingLinks.length;
        const setting = getSetting(numVideos, numLinks);
        librarySettings[id] = setting;
    });
    dispatch({ type: UPDATE_LIBRARY_SETTINGS, payload: {librarySettings} });
};

export const updateLinks = (updatedLinks) => async (dispatch, getState, {getFirebase, getFirestore}) => {
    try {
        const firestore = getFirestore();
        const updatedVideoIds = updatedLinks.map(link => link.videoId);
        const state = getState();
        const firebaseLinks = _.get(state, 'firebase.profile.links', []);
        const notUpdatedLinks = [ ...firebaseLinks ].filter(link => !updatedVideoIds.includes(link.videoId));
        const newLinks = [ ...notUpdatedLinks, ...updatedLinks];
        const uid = _.get(state, 'firebase.profile.uid');
        await firestore.update(`users/${uid}`, {links: [...newLinks]})
        await firestore.get(`users/${uid}`);
        dispatch(updateLibrarySettings())
        toastr.success('updated links!');
    } catch (error) {
        toastr.error('Could not update your links! Check connection and login status.')
        console.log(error);
    }
}