export const CREATE_LABEL = 'CREATE_LABEL';
export const UPDATE_LABEL = 'UPDATE_LABEL';
export const DELETE_LABEL = 'DELETE_LABEL';
export const SYNC_LABELS = 'SYNC_LABELS';

export const UPDATE_LABEL_SETTINGS = 'UPDATE_LABEL_SETTINGS';
const CHECK_MARK = 'check';
const GAME = 'chess knight';
const STUDY = 'coffee';
const DEFAULT = 'bookmark outline';

export const LABEL_ENUMS = {STUDY, GAME, CHECK_MARK, DEFAULT}
export const LABEL_COLOR_ENUMS = {
    [CHECK_MARK]: 'brown',
    [GAME]: 'purple',
    [STUDY]: 'blue',
    [DEFAULT]: 'black'
}