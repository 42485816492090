import { createReducer, createUtil, updateUtil, deleteUtil } from '../../../common/util/reducerUtils';
import { CREATE_COLLECTION, UPDATE_COLLECTION, DELETE_COLLECTION, FETCH_COLLECTIONS } from './collectionConstants';

const createCollection = (state, payload) => {
    return createUtil(payload, state);
}
const updateCollection = (state, payload) => {
    return updateUtil(payload, state);
}
const deleteCollection = (state, payload) => {
    return deleteUtil(payload, state);
}
const fetchCollections = (state, payload) => {
    return payload.collections;
}
export default createReducer({}, {
    [CREATE_COLLECTION]: createCollection,
    [UPDATE_COLLECTION]: updateCollection,
    [DELETE_COLLECTION]: deleteCollection,
    [FETCH_COLLECTIONS]: fetchCollections
})
