import { combineReducers } from "redux";
import videoReducer from "../features/study/videoReducer";
import bookmarkReducer from "../features/study/bookmarkReducer";
import collectionReducer from "../features/library/Collection/collectionReducer";
import { reducer as formReducer} from "redux-form";
import { reducer as toastrReducer} from "react-redux-toastr";
import modalReducer from '../features/modals/modalReducer';
import authReducer from "../features/auth/authReducer";
import asyncReducer from "../features/async/asyncReducer";
import { firebaseReducer } from "react-redux-firebase";
import {firestoreReducer} from "redux-firestore";
import videoSettingsReducer from "../features/study/VideoPlayer/videoSettingsReducer";
import librarySettingsReducer from "../features/library/Collection/librarySettingsReducer";
import labelSettingsReducer from "../features/study/Dashboard/labelSettingsReducer";
import notesReducer from "../features/diary/Note/noteReducer"

const rootReducer = combineReducers({
    collections: collectionReducer,
    videos: videoReducer,
    bookmarks: bookmarkReducer,
    videoSettings: videoSettingsReducer,
    librarySettings: librarySettingsReducer,
    labelSettings: labelSettingsReducer,
    notes: notesReducer,
    modals: modalReducer,
    form: formReducer,
    async: asyncReducer,
    auth: authReducer,
    toastr: toastrReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer
});

export default rootReducer;