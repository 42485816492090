export const createReducer = (initialState, fnMap) => {
    return (state = initialState, { type, payload }) => {
        const handler = fnMap[type];
        return handler ? handler(state, payload) : state
    }
}

/* add generic utils here */

export const createUtil = (payload, state) => {
    if(state.byId[payload.id]) {
        return state;
    }
    return {
        byId: Object.assign({}, state.byId, {[payload.id]: payload}),
        allIds: [...state.allIds, payload.id]
    }
}

export const updateUtil = (payload, state) => {
     delete state.byId[payload.id]
     return {
         byId: {...state, [payload.id]: payload},
         allIds: state.allIds
     };
}
// delete by id
export const deleteUtil = (payload, state) => {
    delete state.byId[payload.id]
    return {
        byId: {...state.byId},
        allIds: state.allIds.filter(id => id !== payload.id)
    };
}

// const testState = {
//     allIds: ['a','b','c','d'],
//     byId: {
//         a: {name: 'apple', id: 'a'},
//         b: {name: 'banana', id: 'b'},
//         c: {name: 'citrus', id: 'c'},
//         d: {name: 'doner', id: 'd'},
//     }
// }

// console.log(createUtil({id: 'e', name: 'bonny'}, testState))

