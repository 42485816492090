import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBbNqiXz7A6q1qvmyPWEAZrNrSNFJ5tG8g",
    authDomain: "gcp-ashi-garami.firebaseapp.com",
    databaseURL: "https://gcp-ashi-garami.firebaseio.com",
    projectId: "gcp-ashi-garami",
    storageBucket: "gcp-ashi-garami.appspot.com",
    messagingSenderId: "9285433818",
    appId: "1:9285433818:web:4b87c78607bf741fc168e3",
    measurementId: "G-PVYVXHFWS7"
  };

  firebase.initializeApp(firebaseConfig);
  firebase.firestore();

export default firebase;