import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Segment, Button, Label, Divider } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import TextArea from '../../../common/form/TextArea';
import TextInput from '../../../common/form/TextInput';
import { createNote } from '../Note/noteActions';

const getTimeString = (seconds) => {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
}

const mapStateToProps = ({firebase, firestore}, {note}) =>{
  const uid = firebase.profile.uid;
  const notes = note && firestore.data.notes[uid][note.videoId];
  const foundNote =  notes && notes.filter(n => n.id === note.id)[0];
  if(note && note.id) {
      return {
          initialValues: foundNote,
      }
  }
  return {
      initialValues : {}  
  }
}

const actions = {
  createNote
};


class NoteForm extends Component {
  render() {
    const { handleSubmit, error, currentBookmark, time, videoId, closeModal, note, player, createNote } = this.props;
    return (
      <Form size="huge" onSubmit={handleSubmit((values) => { createNote({...values, time, videoId, noteId: note && note.id  }); player.play(); closeModal()})}>
        <Segment>
        <p>
          {`time: ${getTimeString(time)} `}
          </p>
          <p>
           {`segment: ${currentBookmark} `}
          </p>
          <Field
            name="noteText"
            component={TextArea}
            type="text"
            placeholder="Take some notes here. OSS!"
          />
            <Divider horizontal > Reminders </Divider>
          <Field
            name="reminderText"
            component={TextInput}
            type="text"
            placeholder="ex. don't cross feet, keep hips high, watchout for back exposure"
          />
          {error && <Label basic color='red'>{error ? 'could not save note :(': null}</Label>}
          <Button fluid size="large" color='black' type="submit">
            Save
          </Button>
          <Button onClick={() => {closeModal(); player.play()}} fluid size="large" color='red'>
            Cancel
          </Button>
        </Segment>
      </Form>
    );
  }
}




export default connect(mapStateToProps, actions)(reduxForm({ form: 'noteForm' })(NoteForm));