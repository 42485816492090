import { SET_VIDEO_SETTINGS } from "./videoSettingsConstants";
import _ from 'lodash';

export const setVideoSettings = ({ collectionId, videoId, bookmarkId, time }) => (dispatch, getState) => {
    const state = getState();
    const links = _.get(state, `firebase.profile.links`, [{ id: videoId, link: '' }]);
    const bookmarks = _.get(state, `collections.byId[${collectionId}].videos.byId[${videoId}].bookmarks.byId`, []);
    const video = _.get(state, `collections.byId[${collectionId}].videos.byId[${videoId}]`, { name: '' })
    const bookmark = _.get(bookmarks, `[${bookmarkId}]`, { time: 0 });
    const link = links.filter(link => link.videoId === videoId)[0].link;
    const collectionName = _.get(state, `collections.byId[${collectionId}].name`);
    dispatch({
        type: SET_VIDEO_SETTINGS,
        payload: {
            [collectionId]: {
                videoName: video.name,
                videoId: video.id,
                videoUrl: link,
                bookmarks,
                time: time || bookmark.time,
                collectionName

            }
        }
    });
}
