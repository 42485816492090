import { CREATE_LABEL, UPDATE_LABEL, DELETE_LABEL, UPDATE_LABEL_SETTINGS } from "./labelSettingsConstants";
import { toastr } from "react-redux-toastr";

export const createLabel = (label, collectionId) => {
    return {
        type: CREATE_LABEL,
        payload: {
            id: collectionId,
            label
        }
    }
}

export const updateLabel = (label, collectionId) => {
    return {
        type: UPDATE_LABEL,
        payload: {
            id: collectionId,
            label
        }
    }
}

export const deleteLabel = (label, collectionId) => {
    return {
        type: DELETE_LABEL,
        payload: {
            id: collectionId,
            label
        }
    }
}

export const updateLabelSettings = () => (dispatch, getState) => {
    const currentState = getState();
    const collectionIds = currentState.collections.allIds;
    const labels = currentState.firebase.profile.labels;
    const defaultLabelSettings = collectionIds.reduce((prev,curr)=> {
        prev[curr] = {};
        return prev;
    }, {});
    const labelSettings = {
        byId: { ...defaultLabelSettings,  ...labels},
        dirty: []
    };
    dispatch({ type: UPDATE_LABEL_SETTINGS, payload: {labelSettings} });
};

export const updateLabels = () => async (dispatch, getState, {getFirebase, getFirestore}) => {
    try {
        const labels = getState().labelSettings;
        const dirty = labels.dirty.length > 0;
        if(!dirty) {
            return null;
        }
        const updatedCollectionIds = labels.dirty;
        const firestore = getFirestore();
        const uid = getState().firebase.profile.uid;
        // form updater object
        const updaterObject = updatedCollectionIds.reduce((prev, currId)=>{
            prev[`labels.${currId}`] = labels.byId[currId];
            return prev;
        },{});
        await firestore.update(`users/${uid}`, updaterObject);
        dispatch(updateLabelSettings());
        
    } catch (error) {
        toastr.error('Could not update your bookmark labels! Check connection and login status.')
        console.log(error);
    }
}