import React, { Component } from 'react'
import { CardGroup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import _ from 'lodash';
import NoteCollection from './NoteCollection';
import { LoadingComponent } from '../../../layout/LoadingComponent';

const mapStateToProps = ({collections, librarySettings, async}) => {
  const [bjjFanatics, youtube] = _.partition(collections.allIds, (id) => {
    return collections.byId[id].sourceType === 'BJJ_FANATICS';
  });
  return {
    bjjFanatics: bjjFanatics.map(id => collections.byId[id]),
    youtube: youtube.map(id => collections.byId[id]),
    loading: async.loading,
    librarySettings
  };
}
class NoteCollectionList extends Component {
    render() {
      const { bjjFanatics, loading, librarySettings } = this.props;
      if(loading) return <LoadingComponent />
      if(bjjFanatics.length === 0) {
        return <h1>Please login and/or refresh the page.</h1>
      }
        return (
            <CardGroup>
            {bjjFanatics.map(({id, name, url}) => <NoteCollection setting={librarySettings[id]} key={id} id={id} name={name} url={url} />)}
          </CardGroup>
        )
    }
}

export default connect(mapStateToProps, null)(NoteCollectionList);