import React from 'react'
import {Menu, Dropdown, Image} from 'semantic-ui-react';
import { Link } from 'react-router-dom';


export const SignedInMenu = ({ signOut, auth }) => {
    return (
            <Menu.Item position="right">
              <Image avatar spaced="right" src={auth.photoURL} />
              <Dropdown pointing="top left" text={auth.displayName}>
                <Dropdown.Menu>
                  <Dropdown.Item text="Create Event" icon="plus" />
                  <Dropdown.Item text="My Events" icon="calendar" />
                  <Dropdown.Item text="My Network" icon="users" />
                  <Dropdown.Item text="My Profile" icon="user" />
                  <Dropdown.Item text="Settings" as={Link} to='/settings' icon="settings" />
                  <Dropdown.Item text="Sign Out" onClick={signOut} icon="power" />
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
    )
}

export default SignedInMenu;
