import { createReducer } from '../../../common/util/reducerUtils';
import {UPDATE_LABEL_SETTINGS, CREATE_LABEL, UPDATE_LABEL, DELETE_LABEL } from './labelSettingsConstants';
import _ from 'lodash';

const newLabel = (state, payload) => {
    const id = payload.id;
    return {
        byId: {...state.byId, [id]: {...state.byId[id], [payload.label.bookmarkId]: payload.label.label} },
        dirty: _.uniq([...state.dirty, payload.id])
    };
}
const changeLabel = (state, payload) => {
    const id = payload.id;
    return {
        byId: {...state.byId, [id]: {...state.byId[id], [payload.label.bookmarkId] : payload.label.label} },
        dirty: _.uniq([...state.dirty, payload.id])
    };
}
const removeLabel = (state, payload) => {
    const id = payload.id;
    return {
        byId: {...state.byId, [id]: {...state.byId[id], [payload.id]: null} },
        dirty: _.uniq([...state.dirty, payload.id])
    };
}



const updateLabelSettings = (state, payload) => {
    return payload.labelSettings;
}
export default createReducer({byId: {}, dirty: []}, {
    [UPDATE_LABEL_SETTINGS]: updateLabelSettings,
    [CREATE_LABEL]: newLabel,
    [UPDATE_LABEL]: changeLabel,
    [DELETE_LABEL]: removeLabel
});
