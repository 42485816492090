import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Menu, Container } from 'semantic-ui-react';
import { withFirebase} from 'react-redux-firebase';
import { NavLink, withRouter } from 'react-router-dom';
import SignedInMenu from '../Menus/SignedInMenu';
import SignedOutMenu from '../Menus/SignedOutMenu';
import { openModal } from '../../modals/modalActions';

const actions = {
    openModal
}
const mapStateToProps = (state) => ({ auth: state.firebase.auth })
class NavBar extends Component {
    handleSignIn = () => this.props.openModal('LoginModal')
    handleRegister = () => this.props.openModal('RegisterModal')
    handleSignOut = () => {
        this.props.firebase.logout();
        this.props.history.push('/')
    }

    render() {
        const { auth } = this.props;
        const authenticated = auth.isLoaded && !auth.isEmpty;
        return (
            <Menu tabular>
                <Container>
                    <Menu.Item header>
                        <img src="https://image.flaticon.com/icons/svg/921/921674.svg" alt="logo" />
                        Grappler Pad
                      </Menu.Item>
                    <Menu.Item as={NavLink} to='/home' name="Home" />
                    <Menu.Item as={NavLink} to='/library' name="Library" />
                    <Menu.Item as={NavLink} to='/notepad' name="Notepad" />
                    {/* <Menu.Item as={NavLink} to='/test' name="Test" /> */}

                    <Menu.Item position="right">
                    {authenticated ? <SignedInMenu auth={auth} signOut={this.handleSignOut}/> : <SignedOutMenu signIn={this.handleSignIn} register={this.handleRegister}/>}
                    </Menu.Item>
                </Container>
            </Menu>
        )
    }
}

export default withRouter(withFirebase(connect(mapStateToProps, actions)(NavBar)));