import React, { Component, Fragment } from 'react'
import Video from './Video';
import { connect } from 'react-redux';
import { updateLabels } from './labelSettingsActions';
import _ from 'lodash';
import { LoadingComponent } from '../../../layout/LoadingComponent';
import { withRouter } from 'react-router-dom';

export const hydrateWithLinks = (videos, links = []) => {
    const videoIds = videos.allIds;
    return videoIds.map(id => {
        const linkMatch = links.filter(link => link.videoId === id)[0];
        const link = _.get(linkMatch, 'link', null);
        return { ...videos.byId[id], url:link }
    });
}
const mapState = ({collections, async, firebase}, {match}) => {
    const id = match.params.collectionId;

    const videos = collections.byId && collections.byId[id].videos;
    const hydratedVideos = videos && hydrateWithLinks(videos, firebase.profile.links);

    if(videos && videos.allIds) {
        return {
            videos: _.sortBy(hydratedVideos, 'name'),
            loading: async.loading,
            collectionId: id,
        };
    }
    return {
        videos: [],
        loading: async.loading,
        collectionId: id,

    }
}

const actions = {
    updateLabels
}
class VideoTable extends Component {
    componentDidMount() {
        this.timer = setInterval(this.props.updateLabels, 5 * 1000);
     }
     componentWillUnmount() {
         this.props.updateLabels();
         clearInterval(this.timer);
     }
    render() {
        const { videos, loading } = this.props;
        if(loading) return <LoadingComponent />
        return (
            <Fragment>
                {videos.map((video) => <Video key={video.id} id={video.id} video={video} />)}
            </Fragment>
        )
    }
}

export default withRouter(connect(mapState, actions)(VideoTable))