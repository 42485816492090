import { createReducer, createUtil, updateUtil, deleteUtil } from '../../common/util/reducerUtils';
import { CREATE_BOOKMARK, UPDATE_BOOKMARK, DELETE_BOOKMARK, FETCH_BOOKMARKS } from './bookmarkConstants';


const createBookmark = (state, payload) => {
    return createUtil(payload, state);
}
const updateBookmark = (state, payload) => {
    return updateUtil(payload, state);
}
const deleteBookmark = (state, payload) => {
    return deleteUtil(payload, state);
}
const loadBookmarks = (state, payload) => {
    return payload.bookmarks;
}

export default createReducer({}, {
    [CREATE_BOOKMARK]: createBookmark,
    [UPDATE_BOOKMARK]: updateBookmark,
    [DELETE_BOOKMARK]: deleteBookmark,
    [FETCH_BOOKMARKS]: loadBookmarks
})
