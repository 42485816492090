import { createReducer } from '../../../common/util/reducerUtils';
import { SET_VIDEO_SETTINGS } from './videoSettingsConstants';

const loadSettings = (state, payload) => {
    return {...state, ...payload}
}

export default createReducer({}, {
    [SET_VIDEO_SETTINGS]: loadSettings
})
