import { SubmissionError, reset } from 'redux-form';
import { closeModal } from '../modals/modalActions';
import { toastr } from 'react-redux-toastr';
import { initDataStore } from '../library/Collection/collectionActions';

export const login = (creds) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        try {
            await firebase.auth().signInWithEmailAndPassword(creds.email, creds.password);
            dispatch(closeModal())

        } catch (error) {
            console.log(error);
            throw new SubmissionError({
                _error: error.message
            });
        }
    }
}

export const registerUser = user => async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    try {
        // create the user in 'auth'
        let createdUser = await firebase.auth().createUserWithEmailAndPassword(user.email, user.password);
        console.log(createdUser);
        await createdUser.user.updateProfile({
            displayName: user.displayName
        });
        // update the auth profile
        let newUser = {
            displayName: user.displayName,
            createdAt: firestore.FieldValue.serverTimestamp(),
            email: user.email,
            uid: createdUser.user.uid,
            links: [],
            labels: []
        };
        await firestore.set(`users/${createdUser.user.uid}`, { ...newUser })
        await firestore.set(`notes/${createdUser.user.uid}`, { uid: createdUser.user.uid })
        dispatch(closeModal());
        dispatch(initDataStore());
    } catch (error) {
        console.log(error);
        throw new SubmissionError({
            _error: error.message
        });
    }
    return
}

export const socialLogin = (selectedProvider) =>
    async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        try {
            dispatch(closeModal());
            const user = await firebase.login({ provider: selectedProvider, type: 'popup' })
            if (user.additionalUserInfo.isNewUser) {
                await firestore.set(`users/${user.user.uid}`, {
                    displayName: user.profile.displayName,
                    photoURL: user.profile.avatarUrl,
                    createdAt: firestore.FieldValue.serverTimestamp(),
                    uid: user.user.uid,
                    email: user.profile.email,
                    links: [],
                    labels: []
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

export const updatePassword = (creds) =>
    async (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        const user = firebase.auth().currentUser;
        try {
            await user.updatePassword(creds.newPassword1);
            await dispatch(reset('account'));
            toastr.success('Success', 'Your password has been updated');
        } catch (error) {
            throw new SubmissionError({
                _error: error.message
            });
        }
    }
