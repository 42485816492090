import React from 'react'
import { Grid, List } from 'semantic-ui-react';
import Bookmark from './Bookmark';

const Video = (props) => {
        const { video, selectBookmark } = props;
        const bookmarks = Object.values(video.bookmarks.byId)
        return (
            <Grid.Column width={4}>
                <List>
                    <h4>{video.name} </h4>
    {bookmarks.map(bookmark => <Bookmark bookmarkId={bookmark.id} videoId={video.id} key={bookmark.id} name={bookmark.name} selectBookmark={selectBookmark} />)}
                </List>
            </Grid.Column>
        )
}


export default Video;