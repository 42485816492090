import React, { Component } from 'react'
import { Image, Button, Card } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

class NoteCollection extends Component {
  render() {
    const { name, id } = this.props;
    return (
      <Card>
        <Card.Content>
          <Image
            floated='right'
            size='mini'
            src='https://www.stevensegallery.com/660/660'
          />
          <Card.Header>{name}</Card.Header>
          <Card.Meta>{'John Danaher (BJJ Fanatics)'}</Card.Meta>
        </Card.Content>
        <Card.Content extra>
          <div className='ui two buttons'>
            <Button as={Link} to={`/notepad/${id}`} basic color='blue'>
              Notes
              </Button>
            </div>
          </Card.Content>
        </Card>
        )
    }
}

export default NoteCollection;