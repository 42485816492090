import React, {Component} from 'react';
import {Modal} from 'semantic-ui-react';
import {connect} from 'react-redux';

import NoteForm from '../diary/Note/NoteForm';
import {closeModal} from "./modalActions";

const actions = {closeModal};

class NoteModal extends Component {
    render() {
        return (
            <Modal
                size='tiny'
                open={true}
                onClose={this.props.closeModal}
                closeOnEscape={false}
                closeOnDimmerClick={false}
            >
                <Modal.Header style={{textAlign: 'center'}}>
                    Grappler Pad
                </Modal.Header>
                <Modal.Content>
                        <NoteForm {...this.props}/>
                </Modal.Content>
            </Modal>
        );
    }
}

export default connect(null, actions)(NoteModal);