import React, { Component } from 'react'
import { List } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { createLabel, updateLabel } from './labelSettingsActions';
import { LABEL_ENUMS, LABEL_COLOR_ENUMS } from './labelSettingsConstants';
import { setVideoSettings } from '../VideoPlayer/videoSettingsActions';
import { withRouter } from 'react-router';
import _ from 'lodash';


const mapStateToProps = (state, {match}) => {
    const collectionId = match.params.collectionId;
    return {
        collectionId: collectionId,
        labelSettings: state.labelSettings
     };
};
const actions = {
    createLabel,
    updateLabel,
    setVideoSettings
};
class Bookmark extends Component {
    handleLabelChange = (evt,label) => {
        evt.preventDefault()
        const { createLabel, updateLabel, bookmarkId, collectionId } = this.props;
        // set next label
        const labels = Object.values(LABEL_ENUMS);
        const idx = (labels.indexOf(label) + 1) % labels.length;
        if(label === LABEL_ENUMS.DEFAULT) {
            // create label
            createLabel({
                bookmarkId,
                label: labels[idx]
            }, collectionId)

        } else {
            //update label
            updateLabel({
                bookmarkId,
                label: labels[idx]
            }, collectionId)
        }

    }
    handleBookmarkSelect = (evt) => {
        const {setVideoSettings, collectionId, bookmarkId, videoId} = this.props;
        evt.preventDefault();
        setVideoSettings({videoId, bookmarkId, collectionId })
    }
    render() {
        const { bookmarkId, name, labelSettings, collectionId} = this.props;
        const currentLabel = _.get(labelSettings,`byId[${collectionId}][${bookmarkId}]`, LABEL_ENUMS.DEFAULT);
        let style = {};
        if(currentLabel === LABEL_ENUMS.CHECK_MARK) {
            style = {textDecoration: 'line-through'};
        }
        return (
            <List.Item>
                <List.Icon  color={LABEL_COLOR_ENUMS[currentLabel]} name={currentLabel} onClick={(evt) => this.handleLabelChange(evt, currentLabel)} />
                <List.Content onClick={(evt) => this.handleBookmarkSelect(evt)} >
                <a href=' ' style={style}>{name}</a>
                </List.Content>
            </List.Item>
        )
    }
}

export default withRouter(connect(mapStateToProps, actions)(Bookmark));