import { createReducer } from '../../../common/util/reducerUtils';
import { UPDATE_COLLECTION_SETTINGS, UPDATE_LIBRARY_SETTINGS } from './librarySettingsConstants';
import { LIBRARY_SETTINGS_ENUM } from './librarySettingsActions';

const updateCollectionSetting = (state, payload) => {
    if(!LIBRARY_SETTINGS_ENUM.includes(payload.setting)) return state;
    return {
        ...state,
        [payload.id] : payload.setting
    }
}
const updateLibrarySettings = (state, payload) => {
    return payload.librarySettings;
}
export default createReducer({}, {
    [UPDATE_COLLECTION_SETTINGS]: updateCollectionSetting,
    [UPDATE_LIBRARY_SETTINGS]: updateLibrarySettings
});
