import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react';
import { openModal } from '../../modals/modalActions';
import { getBookmark } from './VideoPlayer';
import { connect } from 'react-redux';

const actions = {
    openModal
}

class BookmarkTracker extends Component {
    constructor(props) {
        super(props)
        this.timer = null;
        this.state = {
            currentBookmark: ''
        }
    }
    componentDidMount() {
        this.timer = setInterval(this.updateBookmark.bind(this), 500)
    }
    componentWillUnmount() {
        clearInterval(this.timer);
    }
    updateBookmark() {
        if (this.props.player.current) {
            const currentTime = this.props.player.current.getState().player.currentTime;
            this.time = currentTime;
            const bookmarks = this.props.bookmarks;
            const newBookmark = getBookmark(currentTime, bookmarks);
            if (newBookmark !== this.state.currentBookmark) {
                this.setState({ currentBookmark: newBookmark })
            }
        }
    }
    render() {
        const { collectionId, videoId, player, openModal } = this.props;
        return (
            <p>
                <Icon name='edit' circular onClick={() => { openModal('NoteModal', { time: this.time, videoId: videoId, collectionId, currentBookmark: this.state.currentBookmark, player: player.current }); player.current.pause(); }} />
                <mark>{this.state.currentBookmark}</mark>
            </p>
        )
    }
}

export default connect(null, actions)(BookmarkTracker);