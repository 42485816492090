import React, { Component } from 'react'
import { Image, Button, Card } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { LIBRARY_SETTINGS_ENUM } from './librarySettingsActions';

class Collection extends Component {
  render() {
    const { name, id, setting } = this.props;
    return (
      <Card>
        <Card.Content>
          <Image
            floated='right'
            size='mini'
            src='https://www.stevensegallery.com/640/640'
          />
          <Card.Header>{name}</Card.Header>
          <Card.Meta>{'John Danaher (BJJ Fanatics)'}</Card.Meta>
        </Card.Content>
        <Card.Content extra>
          <div className='ui two buttons'>
            <Button as={Link} to={`/library/${id}`} basic color='yellow'>
              Setup
              </Button>
            {setting === LIBRARY_SETTINGS_ENUM.COMPLETE ? (<Button as={Link} to={`/study/${id}`} basic color='green'>
              Study
              </Button>) : (<Button as={'a'} href='https://bjjfanatics.com/collections/all/fighter_john-danaher' basic color='red'>
                Buy
              </Button>)}
            </div>
          </Card.Content>
        </Card>
        )
    }
}

export default Collection;