import { createReducer, createUtil, updateUtil, deleteUtil } from '../../common/util/reducerUtils';
import { CREATE_VIDEO, UPDATE_VIDEO, DELETE_VIDEO, FETCH_VIDEOS } from './videoConstants';

const createVideo = (state, payload) => {
    return createUtil(payload, state)
}
const updateVideo = (state, payload) => {
    return updateUtil(payload, state)
}
const deleteVideo = (state, payload) => {
    return deleteUtil(payload, state)
}
const loadVideos = (state, payload) => {
    return payload.videos
}

export default createReducer({}, {
    [CREATE_VIDEO]: createVideo,
    [UPDATE_VIDEO]: updateVideo,
    [DELETE_VIDEO]: deleteVideo,
    [FETCH_VIDEOS]: loadVideos
})
