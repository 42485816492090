import React, { Component} from 'react'
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { Grid } from 'semantic-ui-react';
import VideoTable from './VideoTable';


class Dashboard extends Component {
    render() {
        return (
            <Grid>
                <VideoPlayer />
                <VideoTable />
            </Grid>
        )
    }
}

export default Dashboard;